import { DestroyRef, Injectable, effect, signal } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PrincipalService } from '../auth/principal.service';
import { UserUI } from '../core/model/user.model';
import { AnnouncementCondition } from '../model/announcement.model';
import { CourseAssignmentStatus, StudentAssignmentSearch } from '../model/course-assignment.model';
import { AnnouncementService } from './announcement.service';
import { ChatSubscriptionService } from './chat-subscription.service';
import { CourseAssignmentService } from './course-assignment.service';
import { EventManager } from './event-manager.service';
import { UserMessageService } from './user-message.service';
import { PreferenceService } from './preference.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class SharedService {
    loginCompleteSub: Subscription;
    chatSub: Subscription;
    messageSub: Subscription;
    assignmentSub: Subscription;
    announcementSub: Subscription;
    changeApiProgress = new BehaviorSubject<boolean>(false);
    summaryLoaded = signal(false);
    constructor(
        private principal: PrincipalService,
        private assignmentService: CourseAssignmentService,
        private messageService: UserMessageService,
        private announcementService: AnnouncementService,
        private chatService: ChatSubscriptionService,
        private em: EventManager,
        private storage: Storage,
        private preference: PreferenceService,
        private destroyRef: DestroyRef
    ) {
        this.preference.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(session => {
            if (session.authenticated && !this.summaryLoaded() && session.user?.student) {
                console.log('SHARED-INFO', session, this.summaryLoaded());
                this.getSummaryInfo();
            } else {
                this.summaryLoaded.update(() => false);
            }
        });
        effect(() => {
            console.log('SUMMARY-INFO', this.summaryLoaded());
        });
    }

    getSummaryInfo() {
        console.log('SUMMARY-INFO', this.principal.getAuthenticatedUser());
        this.updateMessageUnreadCounter();
        this.updateAnnouncementUnreadCounter();
        this.getAssigmentCount();
        this.updateChatUnreadMessageCount();
        this.summaryLoaded.update(() => true);
    }

    init() {
        this.destroy();
        this.chatSub = this.em.subscribe(
            [
                'new-comment-message',
                'update-unread-comment-message-count',
                'user-message-update-counter-event',
                'user-message-topics-event',
            ],
            () => {
                this.updateChatUnreadMessageCount();
            }
        );

        this.announcementSub = this.em.subscribe(['announcement-hide'], () => {
            this.updateAnnouncementUnreadCounter();
        });

        this.messageSub = this.em.subscribe(
            [
                'new-assignment',
                'survey-submitted',
                'survey-published',
                'refresh-user-messages',
                'user-message-notification-event',
            ],
            () => {
                this.updateMessageUnreadCounter();
            }
        );

        this.assignmentSub = this.em.subscribe(
            ['new-assignment', 'assignment-result-published', 'survey-submitted', 'survey-published'],
            () => {
                this.getAssigmentCount();
            }
        );
    }

    updateChatUnreadMessageCount() {
        if (this.principal.isAuthenticated()) {
            const account = this.principal.getAuthenticatedUser();
            this.chatService.getUnReadCommentMessageCount(account.id).subscribe(result => {
                let count = 0;
                if (result?.length > 0) {
                    result.forEach(r => {
                        count = count + r.unreadCount;
                    });
                    console.log('UNRREAD-CHAT', count);
                }
                this.storage.set('new-chat-message-count', JSON.stringify(count));
                this.principal.newChatMessageCount.next(count);
            });
        }
    }

    updateMessageUnreadCounter() {
        if (this.principal.isAuthenticated()) {
            const account = this.principal.getAuthenticatedUser();
            console.log('USER', account);
            this.messageService.getUnreadCount(account.id).subscribe(response => {
                console.log('Unread-message', response.body);
                this.storage.set('new-message-count', JSON.stringify(response.body.count));
                this.principal.newMessageCount.next(response.body.count);
            });
        }
    }

    updateAnnouncementUnreadCounter() {
        if (this.principal.isAuthenticated()) {
            const account = this.principal.getAuthenticatedUser();
            console.log('USER', account);
            this.announcementService
                .getUserAnnouncementsCount({
                    schoolId: account.activeSchool.id,
                    condition: AnnouncementCondition.VISIBLE,
                })
                .subscribe(response => {
                    console.log('Unread-announcements', response.body);
                    this.storage.set('new-announcement-count', JSON.stringify(response.body.count));
                    this.principal.newAnnouncementCount.next(response.body.count);
                });
        }
    }

    getAssigmentCount() {
        if (this.principal.isAuthenticated()) {
            const account = this.principal.getAuthenticatedUser();
            console.log('USER', account);
            if (
                (account.ui == UserUI.PARENT || account.ui == UserUI.STUDENT || account.ui == UserUI.TEACHER) &&
                account.activeSchool
            ) {
                const searchDTO = new StudentAssignmentSearch();
                searchDTO.schoolId = account.activeSchool.id;
                searchDTO.userId = account.id;
                searchDTO.academicYearId = account.activeSchool.activeAcademicYear.id;
                searchDTO.statuses = [CourseAssignmentStatus.PUBLISHED];
                if (account.ui == UserUI.PARENT) {
                    searchDTO.studentId = account.student.id;
                }
                searchDTO.courseIds = []; //empty All Courses
                this.assignmentService.getByStudentCountSearch(searchDTO).subscribe(
                    assignments => {
                        console.log('ASSGINMENTS-COUNT', assignments);
                        this.storage.set('total-assignment-count', JSON.stringify(assignments.body.count));
                        this.principal.totalAssignmentCount.next(assignments.body.count);
                    },
                    error => {
                        console.log('not a student', error);
                    }
                );
            }
        }
    }

    destroy() {
        if (this.loginCompleteSub) {
            this.loginCompleteSub.unsubscribe();
        }
        if (this.chatSub) {
            this.chatSub.unsubscribe();
        }
        if (this.messageSub) {
            this.messageSub.unsubscribe();
        }
        if (this.assignmentSub) {
            this.assignmentSub.unsubscribe();
        }
        if (this.announcementSub) {
            this.announcementSub.unsubscribe();
        }
        this.summaryLoaded.set(false);
    }
}
